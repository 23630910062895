<template>
	<div style="background-color: #f8f9fb; min-height: 100%">
	<div class="header">
		<h2 class="page_tit hide">교환 신청 완료</h2>
	</div>

	<div class="container">
		<div class="row">
			<div class="flex_wrap flex-column justify-space-between">
				<div class="intro_top character2 bg_w" style="margin-top: 150px">
					<div class="text_guide pt-20">
						<h4>교환 신청을 완료했습니다.</h4>
					</div>
				</div>

				<div>
					<ul>
						<li>
							<div class="flex justify-space-between">
								<p class="size-px-14">교환 신청 포인트</p>
								<p class="size-px-14 fw-500">{{  result.exchange_point | makeComma }} MAF POINT</p>
							</div>
							<div class="flex justify-space-between pt-10">
								<p class="size-px-14">교환 후 남은 포인트</p>
								<p class="size-px-14 fw-500">{{  result.exchange_after_remant_point | makeComma }} MAF POINT</p>
							</div>
							<div class="flex justify-space-between pt-10">
								<p class="size-px-14">총 교환 수량</p>
								<p class="size-px-16 fw-700" style="color: var(--blue01)">{{ result.tot_exchange_coin_quantity | makeComma}} MAF</p>
							</div>
						</li>
					</ul>
					<hr class="under-line mt-20 mb-20" />
					<p class="size-px-13">*교환내역은 "더보기 > MAF 포인트"에서 확인이 가능합니다.</p>
					<div class="pt-40 pb-30">
						<div class="btn_area">
							<button class="btn_l btn_fill_blue" @click="toPoint">확인</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>


<script>
export default {
	name: 'ExchangeResult'
	, props: ['user', 'result']
	, data: function(){
		return {
			name: 'ExchangeResult'
			, program: {
				name: ''
				, title: ''
				, type: 'mypage'
				, not_footer: true
				, not_header: true
			}
			, item: {
				is_on_alram: true
			}
		}
	}
	, computed: {

	}
	, methods: {
		goBack: function(){
			this.$bus.$emit('goBack')
		}
		, toPoint: function(){
			// this.$bus.$emit('to', { name: 'Point'})
			this.$emit('cancel')
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
	}
}
</script>
