<template>
	<div style="background-color: #f8f9fb; min-height: 100%">
		<div class="header">
			<div class="top top_mafi f-left">
				<div class="util">
					<button class="arrow_white" @click="goBack">
						<i class="icon icon-arrow-left"></i>
					</button>
				</div>
				<h2 class="page_tit">MAF 포인트 교환</h2>
			</div>
		</div>

		<div class="subindex_wrap pt-50 bg-white">
			<div class="container">
				<div class="subindex_mainbox">
					<!-- 보유 MAF 포인트 -->
					<div>
						<h3 class="size-px-16 fw-500">보유 MAF 포인트</h3>
						<div class="bright_input input_count mt-15 input_point">
							<input v-model="item_point.holding_point" type="number" disabled />
							<span class="input_text_count">MAF POINT</span>
						</div>
					</div>

					<!-- 교환 할 MAX 포인트 -->
					<div>
						<div class="flex justify-space-between" style="align-items: end">
							<h3 class="size-px-16 fw-500">교환 할 MAF 포인트</h3>
							<span class="size-px-12 color-bbb">최소 10,000MAF POINT</span>
						</div>
						<div class="flex pt-15">
							<button class="btn_l btn_fill_blue mr-10 flex_shrink" @click="setMax">MAX</button>
							<div class="bright_input input_count input_point">
								<input v-model="item.amount" type="number" :rules="[$rules.demical(item, 'amount', {min: 10, max: 0})]" @change="checkAmount" @input="checkAmount"/>
								<span class="input_text_count">MAF POINT</span>
							</div>
						</div>
					</div>

					<div class="arrow-line mt-35 mb-35">
						<hr class="under-line" />
						<div class="arrow">
							<img :src="require('@/assets/image/ico_border_arrow.svg')" alt="" />
						</div>
					</div>

					<ul>
						<li>
							<div class="flex justify-space-between">
								<p class="size-px-14">교환 비율</p>
								<p class="size-px-14 fw-500">{{  item_exchange.exchange_basic_point_quantity | makeComma }}MAF POINT = {{ item_exchange.exchange_target_coin_quantity | makeComma }}MAF COIN</p>
							</div>
							<div class="flex justify-space-between pt-10">
								<p class="size-px-14">교환 후 남은 포인트</p>
								<p class="size-px-14 fw-500">{{  after_point | makeComma }} MAF POINT</p>
							</div>
							<div class="flex justify-space-between pt-10">
								<p class="size-px-14">총 교환 수량</p>
								<p class="size-px-16 fw-700" style="color: var(--blue01)">{{  total_point | makeComma }} MAF</p>
							</div>
						</li>
					</ul>
					<hr class="under-line mt-20" />
					<div class="mb-150">
						<p class="size-px-16 fw-500 color-333">유의사항</p>
						<ul class="list_dot mt-15">
							<li class="color-333">교환 신청은 {{ item_exchange.exchange_possible_minim_point_quantity | makeComma }}MAF 포인트 이상 가능합니다.</li>
							<li class="color-333">교환 신청 설정은 1MAF 포인트 단위로 신청 가능합니다.</li>
							<li class="color-333">교환 후 취소는 불가능합니다.</li>
						</ul>
					</div>

					<div class="bottom_btn bg-white">
						<div class="btn_container">
							<div class="btn_area">
								<button class="btn_l btn_fill_blue" :disabled="is_disabled" @click="onExchange">교환 신청</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<div
			v-if="is_on_result"
			class="position-fixed-full"
			style="z-index: 999"
		>
			<ExchangeResult
				:result="item_result"

				@cancel="clear"
			></ExchangeResult>
		</div>
	</div>
</template>


<script>
	import ExchangeResult from "@/view/Main/ExchangeResult";
	export default {
		name: 'Exchange'
		,
		components: {ExchangeResult},
		props: ['user']
		, data: function(){
			return {
				name: 'exchange'
				, program: {
					name: ''
					, title: ''
					, type: 'mypage'
					, not_footer: true
				}
				, item_point: {
					hold_amount: 100000
					, amount: ''
				}
				, item: {
					amount: ''
				}
				, item_result: {

				}
				, item_exchange: {

				}
				, is_on_result: false
			}
		}
		, computed: {
			is_disabled: function(){
				let t = true
				if(Number(this.item.amount) > 0 && Number(this.item_point.holding_point) >= Number(this.item.amount) && Number(this.item.amount) >= Number(this.item_exchange.exchange_possible_minim_point_quantity) ){
					t = false
				}
				return t
			}
			, after_point: function(){
				let t = 0

				if(this.item.amount > 0){
					t = Number(this.item_point.holding_point) - Number(this.item.amount ? this.item.amount: 0)
				}

				return t
			}

			, total_point: function(){
				let t = 0

				t = Number(this.item.amount) * Number(Number(this.item_exchange.exchange_target_coin_quantity) / Number(this.item_exchange.exchange_basic_point_quantity))

				return t
			}
		}
		, methods: {
			goBack: function(){
				this.$bus.$emit('goBack')
			}
			, toResult: function(){
				// this.$bus.$emit('to', { name: 'ExchangeResult'})
				this.is_on_result = true
			}
			, onExchange: function(){
				console.log(this.user)
				if(this.user.mphone_self_certfc_fg == 'Y'){
					this.$bus.$off('pinCallback')
					this.$bus.$emit('onPin', 'check')
					this.$bus.$on('pinCallback', (type, pin)=> {
						console.log(type)
						this.postExchange(pin)
					})
				}else{
					this.$bus.$emit('onPersonalConfirm', {name: 'exchange'})
				}
			}
			, async postExchange(pin){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_point
						, data: {
							member_number: this.user.member_number
							, exchange_point: Number(this.item.amount)
							, pin_number: pin
						}
						, type: true
					})
					if(result.success){
						this.item_result = result.data
						this.toResult()
					}else{
						throw result.message
					}
				}catch (e){
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})

				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, async getData(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_my_point
						, data: {
							member_number: this.user.member_number
						}
						, type: true
					})
					if(result.success){
						this.item_point = result.data
					}else{
						throw result.message
					}
				}catch (e){
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})

				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, async getExchangeInfo(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_point_detail
						, data: {
							member_number: this.user.member_number
						}
						, type: true
					})
					if(result.success){
						this.item_exchange = result.data
					}else{
						throw result.message
					}
				}catch (e){
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})

				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, setMax: function(){
				this.item.amount = this.item_point.holding_point
			}
			, checkAmount: function(){
				if(this.item.amount > this.item_point.holding_point){
					this.item.amount = this.item_point.holding_point
				}
			}
			, clear: function(){
				this.item_point.holding_point = this.item_result.exchange_after_remant_point
				this.item = {}
				this.item_result = {}
				this.is_on_result = false
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getData()
			this.getExchangeInfo()
		}
	}
</script>

<style scoped>
.bright_input.input_point input {
	text-align: right;
	font-size: 16px !important;
	font-weight: 700;
	color: var(--blue02);
	padding-right: 104px;
}
.bright_input.input_point input:disabled {
	color: var(--dark-Gray02);
}

.bright_input .input_text_count {
	font-size: 14px;
	color: var(--dark-Gray02);
}
button:disabled {
	color: var(--white);
}
</style>
